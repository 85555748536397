import NavItem from './NavItem';
import MenuLinks from './config';
import PropTypes from 'prop-types';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import React, { useEffect, useState } from 'react';
import MyAvatar from 'src/components/MyAvatar';
import Scrollbars from 'src/components/Scrollbars';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
import firebase from 'firebase/app';

import _ from 'lodash';

import { alpha, makeStyles,useTheme } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Button,
  Drawer,
  Hidden,
  Typography,
  ListSubheader
} from '@material-ui/core';

import { getCountBadgeActionForRetailer } from 'src/api/badgeAction';


import AppBar from '@material-ui/core/AppBar';
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import Account from '../TopBar/Account';
import Notifications from '../TopBar/Notifications';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import Toolbar from '@material-ui/core/Toolbar';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const useStyles = makeStyles((theme) => {
  const isLight = theme.palette.mode === 'light';

  return {
    root: {
      boxShadow: 'none',
      backdropFilter: 'blur(8px)',
      backgroundColor: alpha(theme.palette.background.default, 0.72),
      [theme.breakpoints.up('lg')]: {
        paddingLeft: DRAWER_WIDTH
      }
    },

    appBar: {
      boxShadow: 'none',
      backdropFilter: 'blur(8px)',
      backgroundColor: alpha(theme.palette.background.default, 0.72),
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: DRAWER_WIDTH,
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      // width: DRAWER_WIDTH,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
    },
    drawerClose: {
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(10),
      },
    },
    subHeader: {
      ...theme.typography.overline,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      color: theme.palette.text.primary
    },
    account: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 2.5),
      margin: theme.spacing(1, 2.5, 5),
      borderRadius: theme.shape.borderRadiusSm,
      background: theme.palette.grey[isLight ? 200 : 800]
    },
    accountHide: {
      alignItems: 'center',
      padding: theme.spacing(0),
      margin: theme.spacing(5, 0, 5),
      marginLeft: theme.spacing(2),
      background: 'none'
    },
    doc: {
      padding: theme.spacing(2.5),
      borderRadius: theme.shape.borderRadiusMd,
      backgroundColor: isLight
        ? alpha(theme.palette.primary.main, 0.08)
        : theme.palette.primary.lighter
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },    
  };
});

// ----------------------------------------------------------------------

function reduceChildIcon({ array, item, pathname, level }) {
  const key = item.href + level;

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        captionTitle={item.title}
        open={Boolean(match)}
        drawerOpen={false}
      >
        {renderNavItemsIcon({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </NavItem>
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
        captionTitle={item.title}
        drawerOpen={false}
      />
    ];
  }
  return array;
}

function renderNavItemsIcon({ items, pathname, level = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChildIcon({ array, item, pathname, level }),
        []
      )}
    </List>
  );
}

function reduceChild({ array, item, pathname, level, listBadgeAction }) {
  const key = item.href + level;

  const badgeContentFormat = !_.isEmpty(item?.badgeModule) ? listBadgeAction[item?.badgeModule] : '';

  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        badgeModule={item.badgeModule}
        badgeContent={badgeContentFormat}
        open={Boolean(match)}
        drawerOpen={true}
      >
        {renderNavItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </NavItem>
    ];
  } else {
    array = [
      ...array,
      <NavItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
        badgeModule={item.badgeModule}
        badgeContent={badgeContentFormat}
        drawerOpen={true}
      />
    ];
  }
  return array;
}

function renderNavItems({ items, pathname, level = 0, listBadgeAction }) {
  return (
    <List disablePadding>
      {items.reduce(
        (array, item) => reduceChild({ array, item, pathname, level, listBadgeAction }),
        []
      )}
    </List>
  );
}

NavBar.propTypes = {
  onCloseNav: PropTypes.func,
  isOpenNav: PropTypes.bool,
  className: PropTypes.string
};

function NavBar({ isOpenNav, onCloseNav}) {
  const classes = useStyles();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { user } = useAuth();
  const firebaseUser = firebase.auth().currentUser
  
  const [listBadgeAction, setListBadgeAction] = useState({});

  const [modifiedMenuLinks, setModifiedMenuLinks] = useState([])

  useEffect(async () => {
    if (isOpenNav && onCloseNav) {
      onCloseNav();
    }
    
    let belongToDistributor = user.retailerID?.belongToDistributor
    let retailerAccessSetting = user.retailerID?.distributorAccessSetting

    // Filter retailerAccessSetting based on belongToDistributor
    const filteredAccessSetting = retailerAccessSetting.filter((elem) =>
      belongToDistributor.includes(elem.distributorID.toString())
    );


    const modifiedMenuLinksData = MenuLinks.map((obj) => ({
      ...obj,
      items: obj.items.filter((item) => {
        const isDefaultAccess = filteredAccessSetting.some((elem) => elem.accessType === "default");
        const isCustomAccess = !item.access || (filteredAccessSetting.some((elem) => elem.accessType === "custom" && elem.accessItem.includes(item.access)) && item.access !== "allOrder");
        
        const allOrderAccess = item.access === "allOrder" && filteredAccessSetting.some((elem) => ((elem.accessType === "custom" && (elem.accessItem.includes("orders") && elem.accessItem.includes("consignment")))) || elem.accessType === "default");
        const staffPreOrderAccess = item.access === "staffPreOrder" && user.numberStaff > 0;
        return isDefaultAccess || isCustomAccess || allOrderAccess || staffPreOrderAccess;
      }),
    }));

    let finModified = []
    for (const item of modifiedMenuLinksData) {
      if (item.items.length > 0) {
        finModified.push(item)
      }
    }

    
    setModifiedMenuLinks(finModified)

    const token = await firebaseUser.getIdToken();

    const result = await getCountBadgeActionForRetailer(token, {
      userID: user._id
    })

    setListBadgeAction(result)
    
  }, [pathname]);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const renderContent = (
    <Scrollbars >      

      <Link
        underline="none"
        component={RouterLink}
        to="/customer/dashboard"
      >
        <div className={clsx(classes.account, {
                [classes.accountHide]: !open,
              })}>
          <MyAvatar />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }} className={clsx( {
                [classes.hide]: !open,
              })}>
              {user.firstName}
            </Typography>

            <Typography variant="subtitle2" sx={{ color: 'text.secondary', maxWidth: '140px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }} className={clsx( {
                [classes.hide]: !open,
              })}>
              {user?.retailerID?.companyName}
            </Typography>
          </Box>
        </div>
      </Link>

      {modifiedMenuLinks.map((list) => (
        <List
          disablePadding
          key={list.subheader}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              className={clsx(classes.subHeader, {
                [classes.hide]: !open,
              })}
            >
              {list.subheader}
            </ListSubheader>
          }
        >
          {open ? renderNavItems({
            items: list.items,
            pathname: pathname,
            listBadgeAction: listBadgeAction
          }) : renderNavItemsIcon({
            items: list.items,
            pathname: pathname
          })}
        </List>
      ))}
    </Scrollbars>
  );

  return (
    <nav className={classes.drawer}>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={isOpenNav}
          variant="temporary"
          onClose={onCloseNav}
          classes={{ paper: classes.drawerPaper }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden smDown>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>          
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <Icon icon={menu2Fill} style={{ color: 'black' }} />
        </IconButton>
        <Box sx={{ px: 2.5, py: 3 }}>
          <RouterLink to="/customer/dashboard" className={clsx( {
                  [classes.hide]: open,
                })}>
            <Logo />
          </RouterLink>
        </Box>
        {/**<Search />*/}
        <Box sx={{ flexGrow: 1 }} />

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:first-of-type)': {
              ml: {
                xs: 0.5,
                sm: 2,
                lg: 3
              }
            }
          }}
        >
          {/**<Languages />*/}
          <Notifications />
          {/**<Settings />*/}
          <Account />
        </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <Box sx={{ px: 2.5, py: 3 }}>
            <RouterLink to="/customer/dashboard" className={clsx( {
                    [classes.hide]: !open,
                  })}>
              <Logo />
            </RouterLink>
          </Box>
          <Box sx={{ pl: 2.5, py: 3 }}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
      
          </Box>
          
        </div>
        {renderContent}
      </Drawer>
      </Hidden>
    </nav>
  );
}

export default NavBar;